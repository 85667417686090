import { BrowserRouter, Routes, Route, useLocation, redirect } from 'react-router-dom';

//CSS
import './styles/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

//LAYOUT
import Layout from './pages/_layout/Layout';

//PAGES
import Home from './pages/Home/HomePage';
import About from './pages/About/AboutPage';
import Login from './pages/Login/LoginPage';
import HennaPage from './pages/Henna/HennaPage';
import StandesamtPage from './pages/Standesamt/StandesamtPage';
import QuincyPage from './pages/Quincy/QuincyPage';
import ImanNikahPage from './pages/ImanNikah/ImanNikahPage';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout/>}>
            <Route index element={<Home/>} />
            <Route path="about" element= {<About />} />
            <Route path="login" element= {<Login />} />
            <Route path="henna" element= {<HennaPage />} />
            <Route path="standesamt" element= {<StandesamtPage />} />
            <Route path="quincy" element= {<QuincyPage />} />
            <Route path="imamnikah" element= {<ImanNikahPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>     
  );
}

export default App;
