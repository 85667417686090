import { Outlet, useLocation } from "react-router-dom";

// Components
import Menu from "../../components/Menu/Menu";

// CSS
import './Layout.css';

export default function _layout() {
    const root = useLocation().pathname.toLowerCase();
    
    return (
        <div className={root === '/' ? 'layout home-background' : 'layout other-background'}>
            <div className="layout-content">
                <div className="layout-navbar"> 
                    <Menu />
                </div>
                <div className="layout-main">
                    <Outlet />
                </div>
            </div>
        </div>
    );
}