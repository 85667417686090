import { Component } from "react"; 

//CSS
import './AboutPage.css';

interface State {
    photoIndex: number;
};

class AboutPage extends Component<{}, State> {
    timerId: any;
    maxIndex: number;

    constructor(props: any) {
        super(props);

        this.state = {
            photoIndex: 0
        }

        this.maxIndex = 4;
    }

    componentDidMount() {
        this.timerId = setInterval(this.increaseIndex, 5000);
    }

    componentWillUnmount() {
        clearInterval(this.timerId);
    }
    
    increaseIndex = () => {
        let newIndex = this.state.photoIndex + 1;

        if (newIndex > this.maxIndex)
            newIndex = 0;

        this.setState({
            photoIndex: newIndex
        });
    }

    render() {
        return(
            <div className="about">
                <div className="background-blur">
                </div>
                <div className="about-left">
                    <img src="./img/about0.jpeg" alt="" className={`visible ${this.state.photoIndex !== 0 ? 'animationfadeOut' : ''}`} />
                    <img src="./img/about1.jpeg" alt="" className={`visible ${this.state.photoIndex !== 1 ? 'animationfadeOut' : ''}`} />
                    <img src="./img/about2.jpeg" alt="" className={`visible ${this.state.photoIndex !== 2 ? 'animationfadeOut' : ''}`} />
                    <img src="./img/about3.jpeg" alt="" className={`visible ${this.state.photoIndex !== 3 ? 'animationfadeOut' : ''}`} />
                    <img src="./img/about4.jpeg" alt="" className={`visible ${this.state.photoIndex !== 4 ? 'animationfadeOut' : ''}`} />
                </div>
                <div className="about-right">
                    <div className="about-text">
                        <p className="about-title">
                            Über uns
                        </p>
                        <p className="about-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Purus non enim praesent elementum facilisis. Non tellus orci ac auctor augue mauris. Ullamcorper a lacus vestibulum sed arcu. Amet volutpat consequat mauris nunc congue nisi vitae suscipit tellus. Imperdiet sed euismod nisi porta lorem mollis. Pellentesque nec nam aliquam sem et tortor consequat. Ac tincidunt vitae semper quis lectus. Pharetra pharetra massa massa ultricies. Massa sed elementum tempus egestas. Proin nibh nisl condimentum id venenatis a.
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default AboutPage;