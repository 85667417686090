import { Component } from "react";

// CSS
import './QuincyPage.css';

class QuincyPage extends Component<{}> {
    render() {
        return (
            <>
                <div className="background-quincy-blur">
                </div>
                <div className="quincy">
                    {/* Intro */}
                    <div className="intro">
                        <div className="centered-area">
                            <p className="title">Standesamt Afterparty</p>
                            <p className="subtitle">02-03-2024</p>
                        </div>
                    </div>

                    {/* Location */}
                    <div className="section-group">
                        <div className="section-title quincy-background">
                            Location
                        </div>
                        <div className="section-content">
                            <div className="location">
                                <div className="location-image-section">
                                    <div className="location-image">
                                        <img src="./img/quincy-location.jpg" alt="" />
                                    </div>
                                </div>
                                <div className="location-info">
                                    <p className="headline">Quincy Rooftop Bar</p>
                                    <p className="url">
                                        <strong>Adresse: </strong>
                                        <a target="_blank" rel="noreferrer" href="https://www.google.com/maps/place/Quincy+Rooftop+Bar/@47.5037072,8.7249461,17z/data=!3m1!4b1!4m6!3m5!1s0x479a996716a199bb:0x50cb862f2de3d9e!8m2!3d47.5037036!4d8.727521!16s%2Fg%2F11pwv71h_4?authuser=0&entry=ttu">Theaterstrasse 17, 8400 Winterthur, 23. Stock</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Timeline */}
                    <div className="section-group">
                        <div className="section-title quincy-background">
                            Ablauf
                        </div>
                        <div className="section-content">
                            <div className="timeline">
                                <div className="timeline-content">
                                    <div className="timeline-image quincy-background">
                                        <img alt="" src="./icons/aperol-icon.png"></img>
                                    </div>
                                    <div className="timeline-description">
                                        <p><strong>20:00 Uhr Ankunft Gäste & kleines Apéro</strong></p>
                                    </div>
                                </div>
                                <div className="timeline-content">
                                    <div className="timeline-image quincy-background">
                                        <img alt="" src="./icons/cake-icon.png"></img>
                                    </div>
                                    <div className="timeline-description">
                                        <p><strong>21:30 Uhr Erster Tanz + Torte schneiden</strong></p>
                                    </div>
                                </div>
                                <div className="timeline-content">
                                    <div className="timeline-image quincy-background">
                                        <img alt="" src="./icons/party-icon.png"></img>
                                    </div>
                                    <div className="timeline-description">
                                        <p><strong>22:00 Uhr Partyyyyy</strong></p>
                                    </div>
                                </div>
                            </div>  
                        </div>
                    </div>

                    {/* Parking */}
                    <div className="section-group">
                        <div className="section-title quincy-background">
                            Parkplatz
                        </div>
                        <div className="section-content">
                            <p>
                                Neben dem Gebäude gibt es eine kleine Anzahl an Parkplätze, deshalb empfehlen wir Euch, eine Fahrgemeinschaft zu bilden oder mit dem ÖV anzureisen. In der Umgebung gibt es die folgenden Parkhäuser:
                            </p>
                            <br />
                            <br />
                            <div className="location">
                                <div className="location-image-section">
                                    <div className="location-image">
                                        <img src="./img/parkhaus-manor-location.jpg" alt="" />
                                    </div>
                                </div>
                                <div className="location-info">
                                    <p className="headline">Parkhaus Manor/Stadtgarten</p>
                                    <p className="url">
                                        <strong>Adresse: </strong>
                                        <a target="_blank" rel="noreferrer" href="https://www.google.com/maps?q=Parkhaus+Manor+Museumstrasse+74,+8400+Winterthur&source=lmns&entry=mc&bih=1305&biw=1718&rlz=1C1VDKB_enGB1010GB1010&hl=pt-PT&sa=X&ved=2ahUKEwjk6fug3YCDAxWsTaQEHfC_APUQi6AMKAF6BAgBEAQ">Museumstrasse 74, 8400 Winterthur</a>
                                    </p>
                                </div>
                            </div>

                            <br /><br />

                            <div className="location">
                                <div className="location-image-section">
                                    <div className="location-image">
                                        <img src="./img/parkhaus-theater-location.jpg" alt="" />
                                    </div>
                                </div>
                                <div className="location-info">
                                    <p className="headline">Parkhaus Theater</p>
                                    <p className="url">
                                        <strong>Adresse: </strong>
                                        <a target="_blank" rel="noreferrer" href="https://www.google.com/maps/place/Museumstrasse+64,+8400+Winterthur/@47.5015006,8.7254348,17z/data=!3m1!4b1!4m6!3m5!1s0x479a9982a759dbdd:0xffa96e23ad40b6c4!8m2!3d47.501497!4d8.7280097!16s%2Fg%2F11f5dh8l3q?hl=pt-PT&entry=ttu">Museumstrasse 64, 8400 Winterthur</a>
                                    </p>
                                </div>
                            </div>

                            <br /><br />

                            <div className="location">
                                <div className="location-image-section">
                                    <div className="location-image">
                                        <img src="./img/parkhaus-bahnhof-location.jpg" alt="" />
                                    </div>
                                </div>
                                <div className="location-info">
                                    <p className="headline">Parkhaus Bahnhof Winterthur</p>
                                    <p className="url">
                                        <strong>Adresse: </strong>
                                        <a target="_blank" rel="noreferrer" href="https://www.google.com/maps/place/Parkhaus+Bahnhof+Winterthur/@47.5018134,8.7214072,17z/data=!3m1!4b1!4m6!3m5!1s0x479a997738f8f1b7:0x4f3328857cd0d081!8m2!3d47.5018134!4d8.7239821!16s%2Fg%2F11h52zqmmt?hl=pt-PT&entry=ttu">Rudolfstrasse 20, 8400 Winterthur</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* DessCode */}
                    <div className="section-group">
                        <div className="section-title quincy-background">
                            Dresscode
                        </div>
                        <div className="section-content centered-content">
                            <div>
                                <p>Der Allgemeine Dresscode ist sehr feierlich / elegant. </p>
                                <br />
                                <p><strong>Bitte meidet folgende Farben für Anzüge:</strong></p>
                                <ul>
                                    <li>Weiss</li>
                                    <li>Beige</li>
                                    <li>Braun</li>
                                </ul>
                                <br />
                                <p><strong>Bitte meidet folgende Farben für Kleider:</strong></p>
                                <ul>
                                    <li>Weiss</li>
                                    <li>Schwarz</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    {/* Gifs */}
                    <div className="section-group">
                        <div className="section-title quincy-background">
                            Geschenke
                        </div>
                        <div className="section-content">
                            <p>
                                Die Vorfreude auf unseren Tag steigt und wir können es kaum erwarten, diesen besonderen Moment mit Euch zu teilen. Viele von Euch haben bereits die Frage nach Geschenken gestellt, und wir schätzen Eure liebevolle Aufmerksamkeit sehr.
                            </p>
                            <br />
                            <p>
                                In Anbetracht unserer Zukunftspläne haben wir uns dazu entschlossen, auf materielle Geschenke zu verzichten. Eure Anwesenheit ist für uns das grösste Geschenk, und wenn Ihr dennoch Eure Glückwünsche teilen möchtet, würden wir uns über einen Beitrag zu unserem gemeinsamen Start ins Eheleben freuen
                            </p>
                            <br />
                            <p>
                                Für eure Bequemlichkeit werden vor Ort Barzahlungen in einer dafür bereitgestellten Truhe oder per Twint möglich sein.
                            </p>
                        </div>
                    </div>

                    {/* Additional */}
                    <div className="section-group">
                        <div className="section-title quincy-background">
                            Weiteres
                        </div>
                        <div className="section-content">
                            <p>Damit sich jeder von Euch sorgenfrei amüsieren kann, übernehmen wir die Kosten für Getränke (Softgetränke, Prosecco, Bier) bis zu einer bestimmten Limite. Sollte die Limite erreicht sein, würden wir Euch bitten, die Kosten für eventuelle weitere Getränke selbst zu übernehmen. Wir sind sicher, dass dies für Verständnis sorgt und freuen uns darauf, mit Euch den Abend zu geniessen.</p>
                            <p>Wir freuen uns darauf, mit Euch zu feiern und gemeinsam eine grossartige Zeit zu erleben!</p>
                            <br />
                            <br />
                            <p><strong>Sind Kinder erlaubt?</strong></p>
                            <p>
                                Nein. Wir haben uns entschieden diese Feier als kinderfreie Veranstaltung zu organisieren, um sicherzustellen, dass Ihr und die anderen Gäste die Möglichkeit haben die Atmosphäre in vollen Zügen (entspannt) zu geniessen.
                            </p>
                            <br />
                            <br />
                            <p><strong>Begleitung erlaubt?</strong></p>
                            <p>
                                Wir bitten Euch, keine Gäste mitzubringen, da wir unseren besonderen Tag so intim wie möglich mit der Familie und engen Freunden halten möchten. Aufgrund der begrenzten Kapazität können wir nur die auf der Einladung genannten Kolleginnen und Kollegen an diesem Tag teilnehmen lassen. Vielen Dank für das Verständnis.
                            </p>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default QuincyPage;