import { Component } from "react";

// CSS
import './ImanNikahPage.css';

class ImanNikahPage extends Component<{}> {
    render() {
        return (
            <>
                <div className="background-imannikah-blur">
                </div>
                <div className="imannikah">
                    
                    {/* Intro */}
                    <div className="intro">
                        <div className="centered-area">
                            <p className="title">Imam Nikah</p>
                            <p className="subtitle">09-03-2024</p>
                        </div>
                    </div>

                    {/* Location */}
                    <div className="section-group">
                        <div className="section-title imannikah-background">
                            Location
                        </div>
                        <div className="section-content">
                            <div className="location">
                                <div className="location-image-section">
                                    <div className="location-image">
                                        <img src="./img/imannikah-location.jpg" alt="" />
                                    </div>
                                </div>
                                <div className="location-info">
                                    <p className="headline">Verein Türkgücü Winterthur</p>
                                    <p className="url">
                                        <strong>Adresse: </strong>
                                        <a target="_blank" rel="noreferrer" href="https://www.google.com/maps/place/Verein+T%C3%BCrkg%C3%BCc%C3%BC+Winterthur/@47.5038773,8.7280943,15z/data=!4m6!3m5!1s0x479a99789aec561f:0x375c74f9a8f77259!8m2!3d47.5038773!4d8.7280943!16s%2Fg%2F11c4bg6pzg?entry=ttu">Theaterstrasse 25, 8400 Winterthur</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Timeline */}
                    <div className="section-group">
                        <div className="section-title imannikah-background">
                            Ablauf
                        </div>
                        <div className="section-content">
                            <div className="timeline">
                                <div className="timeline-content">
                                    <div className="timeline-image imannikah-background">
                                        <img alt="" src="./icons/mosque-icon.png"></img>
                                    </div>
                                    <div className="timeline-description">
                                        <p><strong>14:00 Uhr Versammlung in der Moschee</strong></p>
                                    </div>
                                </div>
                                <div className="timeline-content">
                                    <div className="timeline-image imannikah-background">
                                        <img alt="" src="./icons/marriage-icon.png"></img>
                                    </div>
                                    <div className="timeline-description">
                                        <p><strong>14:15 Uhr Trauung</strong></p>
                                    </div>
                                </div>
                                <div className="timeline-content">
                                    <div className="timeline-image imannikah-background">
                                        <img alt="" src="./icons/dinner-icon.png"></img>
                                    </div>
                                    <div className="timeline-description">
                                        <p><strong>14:45 Uhr Essen</strong></p>
                                    </div>
                                </div>
                            </div>  
                        </div>
                    </div>

                    {/* DessCode */}
                    <div className="section-group">
                        <div className="section-title imannikah-background">
                            Dresscode
                        </div>
                        <div className="section-content centered-content">
                            <div>
                                <p>Der Allgemeine Dresscode ist sehr feierlich / elegant. </p>
                                <br />
                                <p><strong>Bitte meidet folgende Farben für Anzüge:</strong></p>
                                <ul>
                                    <li>Weiss</li>
                                    <li>Beige / Braun</li>
                                    <li>Grüntöne / Olivetöne</li>
                                </ul>
                                <br />
                                <p><strong>Bitte meidet folgende Farben für Kleider:</strong></p>
                                <ul>
                                    <li>Weiss</li>
                                    <li>Rot / Bordeauxrot</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    {/* Additional */}
                    <div className="section-group">
                        <div className="section-title imannikah-background">
                            Weiteres
                        </div>
                        <div className="section-content">
                            <p><strong>Mitnehmen</strong></p>
                            <ul>
                                <li>Schal für die Frauen (als Kopfbedeckung für die Zeremonie)</li>
                                <li>Finken (je nach Bedürfnis, beim Eingang der Moschee werden die Schuhe abgezogen. Der Aufenthalt in der Moschee ist auch in Socken erlaubt.)</li>
                            </ul>
                            <br />
                            <br />
                            <p><strong>Sind Kinder erlaubt?</strong></p>
                            <p>
                                Ja, Eure Kleinen sind sehr gerne eingeladen.
                            </p>
                            <br />
                            <br />
                            <p><strong>Begleitung erlaubt?</strong></p>
                            <p>
                                Wir bitten Euch, keine Gäste mitzubringen, da wir unseren besonderen Tag so intim wie möglich mit der Familie und engen Freunden halten möchten. Aufgrund der begrenzten Kapazität können wir nur die auf der Einladung genannten Kolleginnen und Kollegen an diesem Tag teilnehmen lassen. Vielen Dank für das Verständnis.
                            </p>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ImanNikahPage;