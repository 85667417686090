import React from 'react'

import "./HomePage.css"
import { Tab, Tabs } from 'react-bootstrap';

export default function Home() {
    return (
        <div className="homepage">
            <div className='home-text'>
                <p className='home-title'>Wir werden heiraten!</p>
                <p className='home-subtitle'>Samstag, 2 März 2024</p>
            </div>
        </div>
        
    );
}