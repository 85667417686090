import { Component } from "react";

// CSS
import './StandesamtPage.css';

class StandesamtPage extends Component<{}> {
    render() {
        return (
            <>
                <div className="background-standesamt-blur">
                </div>
                <div className="standesamt">
                    {/* Intro */}
                    <div className="intro">
                        <div className="centered-area">
                            <p className="title">Standesamt</p>
                            <p className="subtitle">02-03-2024</p>
                        </div>
                    </div>

                    {/* Location */}
                    <div className="section-group">
                        <div className="section-title standesamt-background">
                            Location
                        </div>
                        <div className="section-content">
                            <div className="location">
                                <div className="location-image-section">
                                    <div className="location-image">
                                        <img src="./img/standesamt-location.jpeg" alt="" />
                                    </div>
                                </div>
                                <div className="location-info">
                                    <p className="headline">Museum Lindengut</p>
                                    <p className="url">
                                        <strong>Adresse: </strong>
                                        <a target="_blank" rel="noreferrer" href="https://www.google.com/maps/place/Heritage+Museum+(in+Lindengut)/@47.5001611,8.7323989,17z/data=!4m14!1m7!3m6!1s0x479a9984b313b817:0xd7e0566f3eb459a1!2sHeritage+Museum+(in+Lindengut)!8m2!3d47.5001611!4d8.7349738!16s%2Fg%2F1hb_dmbms!3m5!1s0x479a9984b313b817:0xd7e0566f3eb459a1!8m2!3d47.5001611!4d8.7349738!16s%2Fg%2F1hb_dmbms?hl=pt-PT&entry=ttu">Römerstrasse 8, 8400 Winterthur</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Timeline */}
                    <div className="section-group">
                        <div className="section-title standesamt-background">
                            Ablauf
                        </div>
                        <div className="section-content">
                            <div className="timeline">
                                <div className="timeline-content">
                                    <div className="timeline-image standesamt-background">
                                        <img alt="" src="./icons/marriage-icon.png"></img>
                                    </div>
                                    <div className="timeline-description">
                                        <p><strong>11:00 Uhr Trauung</strong></p>
                                    </div>
                                </div>
                                <div className="timeline-content">
                                    <div className="timeline-image standesamt-background">
                                        <img alt="" src="./icons/aperol-icon.png"></img>
                                    </div>
                                    <div className="timeline-description"  style={{paddingBottom:0}}>
                                        <p><strong>12:00 Uhr Apéro</strong></p>
                                    </div>
                                    <div className="timeline-description" style={{paddingTop:0}}>
                                        <p>im Verein Türkgücü Winterthur, <a target="_blank" rel="noreferrer" href="https://www.google.com/maps?q=Verein+T%C3%BCrkg%C3%BCc%C3%BC+Winterthur,+Theaterstrasse+25,+8400+Winterthur&rlz=1C1VDKB_enGB1010GB1010&um=1&ie=UTF-8">Theaterstrasse 25, 8400 Winterthur</a></p>
                                    </div>
                                </div>
                                <div className="timeline-content">
                                    <div className="timeline-image standesamt-background">
                                        <img alt="" src="./icons/end-icon.png"></img>
                                    </div>
                                    <div className="timeline-description">
                                        <p><strong>14:00 Uhr Ende</strong></p>
                                    </div>
                                </div>
                            </div>  
                        </div>
                    </div>
                    
                    {/* DessCode */}
                    <div className="section-group">
                        <div className="section-title standesamt-background">
                            Dresscode
                        </div>
                        <div className="section-content centered-content">
                            <div>
                                <p>Der Allgemeine Dresscode ist sehr feierlich / elegant. </p>
                                <br />
                                <p><strong>Bitte meidet folgende Farben für Anzüge:</strong></p>
                                <ul>
                                    <li>Weiss</li>
                                    <li>Beige</li>
                                    <li>Braun</li>
                                </ul>
                                <br />
                                <p><strong>Bitte meidet folgende Farben für Kleider:</strong></p>
                                <ul>
                                    <li>Weiss</li>
                                    <li>Schwarz</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    {/* Additional */}
                    <div className="section-group">
                        <div className="section-title standesamt-background">
                            Mitnehmen
                        </div>
                        <div className="section-content">
                            <p><strong>Mitnehmen</strong></p>
                            <p>
                                Finken (je nach Bedürfnis, beim Eingang der Moschee werden die Schuhe abgezogen. Der Aufenthalt in der Moschee ist auch in Socken erlaubt.)
                            </p>
                            <br />
                            <br />
                            <p><strong>Sind Kinder erlaubt?</strong></p>
                            <p>
                                Ja, Eure Kleinen sind sehr gerne eingeladen.
                            </p>
                            <br />
                            <br />
                            <p><strong>Begleitung erlaubt?</strong></p>
                            <p>
                                Wir bitten Euch, keine Gäste mitzubringen, da wir unseren besonderen Tag so intim wie möglich mit der Familie und engen Freunden halten möchten. Aufgrund der begrenzten Kapazität können wir nur die auf der Einladung genannten Kolleginnen und Kollegen an diesem Tag teilnehmen lassen. Vielen Dank für das Verständnis.
                            </p>
                        </div>
                    </div>

                </div>
            </>
        );
    }
}

export default StandesamtPage;