import { Component, FormEvent } from 'react';

//CSS
import './LoginPage.css';
import Card from 'react-bootstrap/esm/Card';
import Form from 'react-bootstrap/esm/Form';
import InputGroup from 'react-bootstrap/esm/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUser } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/esm/Button';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';

interface LoginPageProps {
    handleNavigation: () => void;
}

class LoginPageComponent extends Component<LoginPageProps>
{
    handleLogin = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    
        const cookies = new Cookies();
        const username = e.currentTarget.username.value;
        
        if (username === undefined || username === '')
            return;

        if (username != 'quincy24' && username != 'quincy1724' && username != 'quincy179' && username != 'quincyall')
            return;

        if (username) {
            const expirationTime = 10 * 60 * 1000;
            cookies.set('millyandburak-username', username, { path: '/', maxAge: expirationTime});
    
            this.props.handleNavigation();
        }
    };

    render() {
        return(
            <div className='login'>
                <div className="background-blur">
                </div>
                <div className="content">
                    <Card className="login-form">
                        <Card.Header>
                            <h1>Login</h1>
                        </Card.Header>
                        <Card.Body>
                        <Form onSubmit={this.handleLogin}>
                            <InputGroup size='lg' className="mb-3">
                                <InputGroup.Text id="basic-addon1">
                                <FontAwesomeIcon icon={faLock} />
                                    </InputGroup.Text>
                                <Form.Control name="username" type="password" placeholder="Passwort" />
                            </InputGroup>
                            <InputGroup size='lg' className="mb-3 button-right">
                                <Button variant="primary" type="submit">
                                    Login
                                </Button>
                            </InputGroup>
                        </Form>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        );
    }
}

function LoginPage() {
    const navigate = useNavigate();
    
    const handleNavigation = () => {
        navigate('/');
    }

    return <LoginPageComponent 
                handleNavigation={handleNavigation}/>
}

export default LoginPage;