import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from "react-router-dom";

//Font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons';
import Cookies from 'universal-cookie';

//STYLES
import "./Menu.css"

export default function Menu() {
    const root = useLocation().pathname.toLowerCase().replace('/', '');
    const location = useLocation();
    const navigate = useNavigate();

    const [menuOpen, setMenuOpen] = useState(false);
    const [canHenna, setCanHenna] = useState(false);
    const [canStandesamt, seCanStandesamt] = useState(false);
    const [canQuincy, setCanQuincy] = useState(false);
    const [canImam, setCanImam] = useState(false);

    useEffect(() => {
        const cookies = new Cookies();
        let username = cookies.get('millyandburak-username');

        setCanHenna(username === 'quincy1724' || username === 'quincy179' || username === 'quincyall');
        seCanStandesamt(username === 'quincyall');
        setCanQuincy(username === 'quincy24' || username === 'quincy1724' || username === 'quincy179' || username === 'quincyall');
        setCanImam(username === 'quincy179' || username === 'quincyall');

        if ((username !== 'quincy24' && username !== 'quincy1724' && username !== 'quincy179' && username !== 'quincyall') && location.pathname !== '/login')
        {
            navigate('/login');
        }
        else if ((username === 'quincy24' || username === 'quincy1724' || username === 'quincy179' || username === 'quincyall') && location.pathname === '/login') {
            navigate('/');
        }

        console.log(location.pathname, username);

        /*if ((location.pathname === '/henna' && (username !== 'quincy24' || username !== 'quincy1724' || username !== 'quincy179' || username !== 'quincyall'))
            || (location.pathname === '/standesamt' && username !== 'quincyall')
            || (location.pathname === '/quincy' && (username !== 'quincy1724' || username !== 'quincy179' || username !== 'quincyall'))
            || (location.pathname === '/imamnikah' && (username !== 'quincy179' || username !== 'quincyall'))) {
            navigate('/');
        }*/

    }, [location.pathname]); 

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    let menuClass = (root === '') ? '' : 'menu-'+root;
    let isMenuHidden = (root === 'login') ? true : false;
    let href = isMenuHidden ? '/login' : '/';

    return (
        <>
            <div className={`collapsed-menu ${isMenuHidden ? 'hidden' : ''}`}>
                <button className={`${menuClass} ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}><FontAwesomeIcon icon={faBars} /></button>
                <ul className={`menu-options ${menuClass} ${menuOpen ? 'open' : ''}`}>
                    <li className={canHenna ? '' : 'hidden'}><a href="/henna">Henna Abend</a></li>
                    <li className={canStandesamt ? '' : 'hidden'}><a href="/standesamt">Standesamt</a></li>
                    <li className={canQuincy ? '' : 'hidden'}><a href="/quincy">Standesamt Afterparty</a></li>
                    <li className={canImam ? '' : 'hidden'}><a href="/imamnikah">Imam Nikah</a></li>
                </ul>
            </div>
            <div className="menu">
                <div className={`menu-left ${isMenuHidden ? 'hidden' : ''}`}>
                    <a href='standesamt' className={canStandesamt ? '' : 'hidden'}>Standesamt</a>
                    <a href='/henna' className={canHenna ? '' : 'hidden'}>Henna Abend</a>
                </div>
                <div className="menu-middle">
                <a href={href}>
                    <div className="menu-text">
                        <p>Mildred</p>
                        <p>&</p>
                        <p>Burak</p>
                    </div>
                </a>
                </div>
                <div className={`menu-right ${isMenuHidden ? 'hidden' : ''}`}>
                    <a href='/quincy' className={canQuincy ? '' : 'hidden'}>Standesamt Afterparty</a>
                    <a href='imamnikah' className={canImam ? '' : 'hidden'}>Imam Nikah</a>
                </div>
            </div>
        </>
    );
}