import { Component } from "react";

// CSS
import './HennaPage.css';

class HennaPage extends Component<{}> {
    render() {
        return (
            <>
                <div className="background-henna-blur">
                </div>
                <div className="henna">
                    {/* Intro */}
                    <div className="intro">
                        <div className="centered-area">
                            <p className="title">Henna Abend</p>
                            <p className="subtitle">17-02-2024</p>
                        </div>
                    </div>

                    {/* Meaning */}
                    <div className="section-group">
                        <div className="section-title henna-background">
                            Bedeutung
                        </div>
                        <div className="section-content">
                            Kina gecesi (Henna Abend) ist eine der ältesten Hochzeitstraditionen in der Türkei. Bei diesem Fest „verabschiedet“ sich die Braut im Kreise ihrer weiblichen Verwandten und Freundinnen von ihrem Elternhaus. Das Fest ist fröhlich, denn es wird getanzt, gesungen und gegessen.
                        </div>
                    </div>

                     {/* Location */}
                     <div className="section-group">
                        <div className="section-title henna-background">
                            Location
                        </div>
                        <div className="section-content">
                            <div className="location">
                                <div className="location-image-section">
                                    <div className="location-image">
                                        <img src="./img/henna-location.jpg" alt="" />
                                    </div>
                                </div>
                                <div className="location-info">
                                    <p className="headline">Katholisches Pfarreizentrum Leepünt</p>
                                    <p className="url">
                                        <strong>Adresse: </strong>
                                        <a target="_blank" rel="noreferrer" href="https://www.google.com/maps/place/Katholisches+Pfarreizentrum+Leep%C3%BCnt/@47.3956796,8.6156861,17z/data=!3m1!4b1!4m6!3m5!1s0x479aa10b8ed36903:0x741f03f040f38f7d!8m2!3d47.3956796!4d8.618261!16s%2Fg%2F1tm67x47?hl=pt-PT&entry=ttu">Leepüntstrasse 14, 8600 Dübendorf</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Timeline */}
                    <div className="section-group">
                        <div className="section-title henna-background">
                            Ablauf
                        </div>
                        <div className="section-content">
                            <div className="timeline">
                                <div className="timeline-content">
                                    <div className="timeline-image henna-background">
                                        <img alt="" src="./icons/door-icon.png"></img>
                                    </div>
                                    <div className="timeline-description">
                                        <p><strong>18:00 Uhr Türöffnung</strong></p>
                                    </div>
                                </div>
                                <div className="timeline-content">
                                    <div className="timeline-image henna-background">
                                        <img alt="" src="./icons/party-icon.png"></img>
                                    </div>
                                    <div className="timeline-description">
                                        <p><strong>18:30 Uhr - 20:00 Uhr PARTY</strong></p>
                                    </div>
                                </div>
                                <div className="timeline-content">
                                    <div className="timeline-image henna-background">
                                        <img alt="" src="./icons/dinner-icon.png"></img>
                                    </div>
                                    <div className="timeline-description">
                                        <p><strong>20:00 Uhr Essen + Party</strong></p>
                                    </div>
                                </div>
                                <div className="timeline-content">
                                    <div className="timeline-image henna-background">
                                        <img alt="" src="./icons/henna-icon.png"></img>
                                    </div>
                                    <div className="timeline-description"  style={{paddingBottom:0}}>
                                        <p><strong>22:00 Uhr Zeremonie</strong></p>
                                    </div>
                                    <div className="timeline-description" style={{paddingTop:0}}>
                                        <p>(dauert max. 30 Minuten, ein Tupfen Henna wird auf den Händen des Brautpaars aufgetragen)</p>
                                    </div>
                                </div>
                                <div className="timeline-content">
                                    <div className="timeline-image henna-background">
                                        <img alt="" src="./icons/dancing-icon.png"></img>
                                    </div>
                                    <div className="timeline-description">
                                        <p><strong>Nach der Zeremonie wird dann weiter getanzt</strong></p>
                                    </div>
                                </div>
                                <div className="timeline-content">
                                    <div className="timeline-image henna-background">
                                        <img alt="" src="./icons/end-icon.png"></img>
                                    </div>
                                    <div className="timeline-description">
                                        <p><strong>24:00 Uhr Ende</strong></p>
                                    </div>
                                </div>
                            </div>  
                        </div>
                    </div>

                    {/* DessCode */}
                    <div className="section-group">
                        <div className="section-title henna-background">
                            Dresscode
                        </div>
                        <div className="section-content centered-content">
                            <div>
                                <p>Der Allgemeine Dresscode ist sehr feierlich / elegant. </p>
                                <br />
                                <p><strong>Bitte meidet folgende Farben für Anzüge:</strong></p>
                                <ul>
                                    <li>Weiss</li>
                                    <li>Beige</li>
                                    <li>Braun</li>
                                </ul>
                                <br />
                                <p><strong>Bitte meidet folgende Farben für Kleider:</strong></p>
                                <ul>
                                    <li>Weiss</li>
                                    <li>Rot / Bordeauxrot</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    {/* Parking */}
                    <div className="section-group">
                        <div className="section-title henna-background">
                            Parkplätze
                        </div>
                        <div className="section-content">
                            <p>
                                Neben dem Gebäude gibt es eine kleine Anzahl an Parkplätze, deshalb empfehlen wir Euch, eine Fahrgemeinschaft zu bilden oder mit dem ÖV anzureisen. In der Umgebung gibt es ansonsten blaue Parkplätze.
                            </p>
                        </div>
                    </div>

                    {/* Gifs */}
                    <div className="section-group">
                        <div className="section-title henna-background">
                            Geschenke
                        </div>
                        <div className="section-content">
                            <p>
                                Die Vorfreude auf den Henna Abend steigt und wir können es kaum erwarten, diesen besonderen Moment mit Euch zu teilen. Viele von Euch haben bereits die Frage nach Geschenken gestellt, und wir schätzen eure liebevolle Aufmerksamkeit sehr.
                            </p>
                            <br />
                            <p>
                                In Anbetracht unserer Zukunftspläne haben wir uns dazu entschlossen, auf materielle Geschenke zu verzichten. Eure Anwesenheit ist für uns das grösste Geschenk, und wenn Ihr dennoch Eure Glückwünsche teilen möchtet, würden wir uns über einen Beitrag zu unserem gemeinsamen Start ins Eheleben freuen.
                            </p>
                            <br />
                            <p>
                                Für Eure Bequemlichkeit werden vor Ort Barzahlungen in einer dafür bereitgestellten Truhe oder per Twint möglich sein.
                            </p>
                        </div>
                    </div>

                    {/* Additional */}
                    <div className="section-group">
                        <div className="section-title henna-background">
                            Weiteres
                        </div>
                        <div className="section-content">
                            <p><strong>Sind Kinder erlaubt?</strong></p>
                            <p>
                                Nein. Wir haben uns entschieden diese Feier als kinderfreie Veranstaltung zu organisieren, um sicherzustellen, dass Ihr und die anderen Gäste die Möglichkeit haben die Atmosphäre in vollen Zügen (entspannt) zu geniessen.
                            </p>
                            <br />
                            <br />
                            <p><strong>Musikrichtung</strong></p>
                            <p>
                                Es werden viele bekannte türkische Festlieder abgespielt. Halay, arabische Bauchtanzlieder, sowie Reggaeton, etc.
                            </p>
                            <p>
                                Die eingeladene enge Kollegen des Bräutigams werden nur kurz beim Essen und nach der Zeremonie dabei sein und auch mittanzen, falls sie möchten 🙂.
                            </p>
                            <br />
                            <br />
                            <p><strong>Begleitung erlaubt?</strong></p>
                            <p>
                            Wir bitten Euch, keine Gäste mitzubringen, da wir unseren besonderen Tag so intim wie möglich mit der Familie und engen Freunden halten möchten. Aufgrund der begrenzten Kapazität können wir nur die auf der Einladung genannten Kolleginnen und Kollegen an diesem Tag teilnehmen lassen. Vielen Dank für das Verständnis.
                            </p>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default HennaPage;